<template>
  <div class="user-form">
    <v-alert
      dense
      outlined
      type="warning"
      color="orange darken-3"
      max-width="400px"
      v-if="!!(user && user.blockedOrRemoved)"
    >
      This account is <strong>{{ user.blockedOrRemovedLabel }}</strong
      >.
    </v-alert>

    <v-form ref="form" class="user-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>

      <v-row class="padding-bottom-0">
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Name</label>
          <v-text-field
            flat
            solo
            class="mt-2"
            placeholder="Full name"
            v-model="form.full_name"
            :error-messages="form.$getError('full_name')"
            :loading="form.$busy"
            :disabled="form.$busy || isDisabled"
          ></v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Email</label>
          <v-text-field
            flat
            solo
            class="mt-2"
            v-model="form.email"
            placeholder="Email address"
            :error-messages="form.$getError('email')"
            :loading="form.$busy"
            :disabled="form.$busy || isDisabled"
          >
            <v-tooltip slot="append" top v-if="user && user.email">
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on" v-if="user.email_verified">{{
                  icons.check
                }}</v-icon>
              </template>
              <span v-if="user.email_verified">Email Verified</span>
            </v-tooltip>
          </v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Phone number</label>
          <v-text-field
            flat
            solo
            class="mt-2"
            v-model="form.phone_number"
            placeholder="Phone number"
            :error-messages="form.$getError('phone_number')"
            :loading="form.$busy"
            :disabled="form.$busy || isDisabled"
          >
            <v-tooltip slot="append" top v-if="user && user.phone_number">
              <template v-slot:activator="{ on }">
                <v-icon
                  color="success"
                  v-on="on"
                  v-if="user.phone_number_verified"
                  >{{ icons.check }}</v-icon
                >
              </template>
              <span v-if="user.phone_number_verified"
                >Phone Number Verified</span
              >
            </v-tooltip>
          </v-text-field>

          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Building</label>
          <SelectBuilding
            flat
            solo
            required
            class="mt-2"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy || isDisabled"
          />

          <span class="red--text"><strong>* </strong></span>
          <template v-if="showPropertyAndProfile">
            <label class="text-field-label">Property</label>
            <SelectProperty
              v-if="hasPropertyPermission"
              flat
              solo
              required
              class="mt-2"
              placeholder="Property"
              v-model="form.property_id"
              :buildingId="form.building_id"
              :pre-select="!isUpdate"
              :error-messages="form.$getError('property_id')"
              :error="form.$hasError('property_id')"
              :loading="form.$busy"
              :disabled="form.$busy || isDisabled"
            />
            <v-text-field
              v-else
              flat
              solo
              class="mt-2"
              :value="propertyName"
              disabled
            ></v-text-field>

            <span class="red--text"><strong>* </strong></span>
            <label class="text-field-label">Profile</label>
            <SelectProfile
              flat
              solo
              required
              class="mt-2"
              placeholder="Profile"
              v-model="form.profile_id"
              :requiredBuilding="requiredBuilding"
              :buildingId="form.building_id"
              :profileId="form.profile_id"
              :pre-select="!isUpdate"
              :error-messages="form.$getError('profile_id')"
              :error="form.$hasError('profile_id')"
              :loading="form.$busy"
              :disabled="form.$busy || isDisabled"
            />
          </template>
          <template v-if="isUpdate">
            <label class="text-field-label">Onboarding Date</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              placeholder="Onboarding Date"
              v-model="user.onboardedDate"
              readonly
            ></v-text-field>
          </template>
        </v-col>
        <v-col cols="12" md="6">
          <template v-if="isUpdate && isOwner">
            <label class="text-field-label">Password</label>
            <PasswordField
              flat
              solo
              required
              class="mt-2"
              placeholder="Password"
              v-model="form.password"
              :error-messages="form.$getError('password')"
              :loading="form.$busy"
              :disabled="form.$busy || isDisabled"
            ></PasswordField>

            <label class="text-field-label">Password Confirmation</label>
            <PasswordField
              flat
              solo
              required
              class="mt-2"
              placeholder="Confirm Password"
              v-model="form.password_confirmation"
              :error-messages="form.$getError('password_confirmation')"
              :loading="form.$busy"
              :disabled="form.$busy || isDisabled"
            ></PasswordField>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-checkbox
            v-if="user"
            :input-value="user.blocked_at"
            label="Disable user account"
            color="orange darken-3"
            class="mt-0"
            :disabled="user && !!user.deleted_at"
            @mousedown="showModal = true"
          ></v-checkbox>

          <div class="mt-4">
            <v-btn
              v-if="hasPermissions"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              :disabled="isDisabled"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-form>

    <ConfirmModal
      v-model="showModal"
      title="Disable Account"
      :message="disableMessage"
      @cancel="showModal = false"
      @confirm="blockAccount"
    ></ConfirmModal>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * User Form
 * ==================================================================================
 **/
import { mapState, mapActions, mapGetters } from 'vuex'
import { mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { isValidMobileNumber } from '@/utils/phone'
import PasswordField from '@/components/fields/PasswordField'
import SelectBuilding from '@/components/fields/SelectBuilding'
import SelectProperty from '@/components/fields/SelectProperty'
import ConfirmModal from '@/components/modals/ConfirmModal'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import SelectProfile from '@/components/fields/SelectProfile'
import ROLE from '@/utils/enums/Role'

export default {
  components: {
    PasswordField,
    SelectBuilding,
    ConfirmModal,
    SelectProperty,
    SelectProfile,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    user: {
      type: Object,
      default: () => {
        return null
      },
    },
  },

  data() {
    return {
      requiredBuilding: true,
      form: new Form({
        full_name: '',
        email: '',
        phone_number: '',
        property_id: '',
        password: '',
        password_confirmation: '',
        building_id: '',
        profile_id: '',
      }),
      showModal: false,
      icons: {
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
    }
  },

  created() {
    this.initForm()
  },

  computed: {
    ...mapState({
      registeredUser: (state) => state.user.userDetails,
      permissions: (state) => state.auth.permissions,
      role: (state) => state.auth.role,
    }),

    ...mapGetters({
      loggedInUserId: 'auth/userId',
    }),

    header() {
      return this.isUpdate ? 'User Information' : 'Create User'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    disableMessage() {
      if (!this.user) return ''

      const action = this.user.blocked_at ? 'unblock' : 'block'
      return `Are you sure you want ${action} this account?`
    },

    submitApi() {
      return this.isUpdate ? this.updateUser : this.registerUser
    },

    isDisabled() {
      return this.user ? !this.user.isUpdatable : false
    },

    isUpdate() {
      return !!this.user
    },

    isOwner() {
      return this.user ? this.loggedInUserId === this.user.id : false
    },

    hasPermissions() {
      return validatePermissions([PERMISSION.USERS_UPDATE], this.permissions)
    },

    hasPropertyPermission() {
      return validatePermissions([PERMISSION.PROPERTY_VIEW], this.permissions)
    },

    isAdminRole() {
      return this.role.includes([
        ROLE.SUPER_ADMIN,
        ROLE.ADMIN,
        ROLE.STANDARD,
        ROLE.READ_ONLY,
      ])
    },

    propertyName() {
      return this.user?.property?.name || ''
    },

    showPropertyAndProfile() {
      return !this.isAdminRole && !this.isOwner
    },
  },

  watch: {
    user(newValue, oldValue) {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      registerUser: 'user/registerUser',
      updateUser: 'user/updateUser',
      blockUser: 'user/blockUser',
    }),

    initForm() {
      if (this.user) {
        this.form.building_id = this.user.building_id
        this.form.full_name = this.user.full_name
        this.form.email = this.user.email
        this.form.phone_number = this.user.phone_number
        this.form.property_id = this.user.property_id
        this.form.profile_id = this.user.profile_id
      }
    },

    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      // Remove spaces from the phone_number field
      if (this.form.phone_number) {
        this.form.phone_number = this.form.phone_number.replace(/\s/g, '')
      }

      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false

            this.form.password = null
            this.form.password_confirmation = null

            this.showSnackbar('User details successfully updated!')
          } else {
            this.showSnackbar('User successfully created!')
            this.$router.push({
              name: 'users',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    blockAccount(value) {
      this.form.$busy = true
      const action = this.user.blocked_at ? 'unblocked' : 'blocked'
      this.blockUser({
        id: this.user.id,
        action,
      })
        .then(() => {
          this.showModal = false
          this.showSnackbar(`User successfully ${action}!`)
        })
        .catch((err) => {
          this.form.$setErrors(this.getValidationErrors(err))
        })
        .finally(() => {
          this.form.$busy = false
        })
    },

    /**
     * Validate form image values on create only
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (!this.form.phone_number && !this.form.email) {
        this.form.$setError(
          'phone_number',
          'You need to fill in either email or phone number'
        )
      }

      if (
        this.form.phone_number &&
        !isValidMobileNumber(this.form.phone_number)
      ) {
        this.form.$setError(
          'phone_number',
          'Phone number must be valid or in national format'
        )
      }

      if (!this.form.property_id) {
        this.form.$setError('property_id', 'Selected property id is invalid.')
      }

      if (!this.form.full_name) {
        this.form.$setError('full_name', 'Full name is invalid')
      }

      if (!this.form.email && !this.form.phone_number) {
        this.form.$setError(
          'email',
          'You need to fill in either email or phone number'
        )
      }

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()
      if (this.isUpdate) {
        form.id = this.user.id

        if (!this.isOwner) {
          delete form.password
          delete form.password_confirmation
        }
      }

      return form
    },
  },
}
</script>
<style lang="scss">
.user-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }
}
</style>
